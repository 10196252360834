
import { PusherService } from './services/pusher.service';
import { FileServer } from './services/fileserver.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpBackend, HttpClient } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './components/layouts/default-layout/default-layout.component';
import { LoggedLayoutComponent } from './components/logged-layout/logged-layout.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { AuthenticationService } from './services/authentication.service';
import { DataService } from './services/data.service';
import { NavigationService } from './services/navigation.service';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ComvService } from './services/comv.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PwaService } from './services/pwa.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';


registerLocaleData(localeFr, 'fr');


export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" },
    { prefix: "./assets/i18n/", suffix: ".static.json" }
  ]);
}


@NgModule({ declarations: [
        AppComponent,
        DefaultLayoutComponent,
        LoggedLayoutComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FlexLayoutModule,
        SharedModule,
        MatTooltipModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.enable_pwa,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
        AuthenticationService,
        DataService,
        TranslateService,
        NavigationService,
        FileServer,
        ComvService,
        PusherService,
        DecimalPipe,
        PwaService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        { provide: LOCALE_ID, useValue: 'en-US' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500, panelClass: ['snack-style'] } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {

  constructor(private translate: TranslateService) {
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('en');

    // const lang = window.navigator.language ? window.navigator.language.split('-')[0] : 'en';
    const lang = 'en';
    translate.use(lang);
  }
}
