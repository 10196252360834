import { Holding } from './Holding';

export class Group {
    id: number = null;
    holding_id: number = null;
    name: string = null;
    logo: string = null;
    linkedin_url: string = null;
    linkedin_tag: string = null;
    twitter_tag: string = null;
    color: string = null;
    isInIntranetwork: boolean = null;
    isVisibleCARD: boolean = null;
    isVisiblePREDICTOR: boolean = null;
    isVisibleMCA: boolean = null;
    isVisibleBMS: boolean = null;
    isVisibleGAMA: boolean = null;
    isVisibleNBB: boolean = null;
    changeChildrenVisibleGAMA: boolean = null;
    changeChildrenVisibleBMS: boolean = null;
    changeChildrenVisibleIsIntranetwork: boolean = null;
    isMajor: boolean = null;
    isBigIndependent : boolean = null;
    isIndependent: boolean = null;
    created_at: string = null;
    updated_at: string = null;
    holding: Holding = null;
    history: any = null;

    constructor(id?: number | Object) {
        if (typeof (id) == 'object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }
};