import { Component, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'card-info-dialog',
  templateUrl: './card-info-dialog.component.html',
  styleUrls: ['./card-info-dialog.component.scss'],
})
export class CardInfoDialog {
  public boolean_labels = {
    true: this.translate.instant('CARD_INFO_DIALOG.YES'),
    false: this.translate.instant('CARD_INFO_DIALOG.NO'),
  };
  public currentYear = moment().year();
  public ready = false;
  public mode;
  public item = null;
  public max_year = null;
  public advertiser_columns = [
    {
      column: 'parentco_name',
      label: this.translate.instant('CARD_INFO_DIALOG.PARENTCO'),
    },
    {
      column: 'brands_name',
      label: this.translate.instant('CARD_INFO_DIALOG.BRANDS'),
    },
    {
      column: 'categories_name',
      label: this.translate.instant('CARD_INFO_DIALOG.CATEGORIES'),
    },

    // {
    //   column: 'segment_name',
    //   label: this.translate.instant('CARD_INFO_DIALOG.SEGMENT'),
    // },
  ];

  public agency_columns = [
    {
      column: 'agency_name',
      label: this.translate.instant('CARD_INFO_DIALOG.AGENCY'),
    },
    {
      column: 'city_name',
      label: this.translate.instant('CARD_INFO_DIALOG.CITIES'),
    },
    {
      column: 'bespokeUnit',
      label: this.translate.instant('CARD_INFO_DIALOG.BESPOKEUNIT'),
    },
    {
      column: 'assignments_name',
      label: this.translate.instant('CARD_INFO_DIALOG.ASSIGNMENTS'),
    },
    {
      column: 'medias_name',
      label: this.translate.instant('CARD_INFO_DIALOG.MEDIAS'),
    },
    {
      column: 'zoneCoverage',
      label: this.translate.instant('CARD_INFO_DIALOG.ZONE_COVERAGE_N1'),
    },
  ];

  public move_columns = [
    {
      column: 'winAt',
      label: this.translate.instant('CARD_INFO_DIALOG.WIN_AT'),
    },
    {
      column: 'movedQuarter',
      label: this.translate.instant('CARD_INFO_DIALOG.MOVED_AT'),
    },
    {
      column: 'effectiveAt',
      label: this.translate.instant('CARD_INFO_DIALOG.EFFECTIVE_DATE'),
    },
    {
      column: 'consultants',
      label: this.translate.instant('CARD_INFO_DIALOG.CONSULTANTS'),
    },
    {
      column: 'isFollowingPitch',
      label: this.translate.instant('CARD_INFO_DIALOG.FOLLOWING_PITCH'),
    },

    {
      column: 'contenders',
      label: this.translate.instant('CARD_INFO_DIALOG.CONTENDERS'),
    },
    {
      column: 'lastPitchDuration',
      label: this.translate.instant('CARD_INFO_DIALOG.LAST_PITCH_DURATION'),
    },

    {
      column: 'agency_name_n1',
      label: this.translate.instant('CARD_INFO_DIALOG.AGENCY_N1'),
    },
  ];

  public previous_columns = [
    {
      column: 'movedQuarter',
      label: this.translate.instant('CARD_INFO_DIALOG.MOVED_AT'),
    },
    {
      column: 'winAt',
      label: this.translate.instant('CARD_INFO_DIALOG.WIN_AT'),
    },
    {
      column: 'effectiveAt',
      label: this.translate.instant('CARD_INFO_DIALOG.EFFECTIVE_DATE'),
    },
    {
      column: 'consultants',
      label: this.translate.instant('CARD_INFO_DIALOG.CONSULTANTS'),
    },
    {
      column: 'contenders',
      label: this.translate.instant('CARD_INFO_DIALOG.CONTENDERS'),
    },
    {
      column: 'isFollowingPitch',
      label: this.translate.instant('CARD_INFO_DIALOG.FOLLOWING_PITCH'),
    },

    {
      column: 'lastPitchDuration',
      label: this.translate.instant('CARD_INFO_DIALOG.LAST_PITCH_DURATION'),
    },
    {
      column: 'agency_name_n1',
      label: this.translate.instant('CARD_INFO_DIALOG.AGENCY_N1'),
    },
  ];
  public showBudget: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<CardInfoDialog>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dataService: DataService
  ) {}

  ngOnInit() {
    let proms = [];
    if (this.data) {
      if(this.data.showBudget !== undefined ) {
        this.showBudget = this.data.showBudget;
      }
      if (this.data.item && this.data.item.id) {
        proms.push(
          // get card
          this.dataService
            .getAsPromise(`comv/cards/cardinfo/${this.data.item.id}`)
            .then((res) => {
              if (res && res.data) {
                this.item = res.data;
              } else {
                this.item = null;
              }
              if (res.meta) {
                this.max_year = res.meta.max_year;
              } else {
                this.max_year = null;
              }
            })
        );
      }
    }

    Promise.all(proms)
      .then((res) => {
        this.ready = true;
      })
      .catch((err) => {
        console.log(err);
        this.ready = true;
        this.item = null;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  formatValue(value, type) {
    if (!this.item) {
      return null;
    }
    let formattedValue = value || null;
    switch (type) {
      case 'comment':
        if (value != null) {
          formattedValue = value.replace('1970-01-01:', '');
        }
        break;
      case 'winAt':
        if (value == '1970-01-01') {
          formattedValue = null;
        }
        break;
      case 'effectiveAt':
      case 'consultants':
        formattedValue = value || null;
        break;
      case 'isFollowingPitch':
        if (value == null) {
          formattedValue = null;
        } else {
          formattedValue = value
            ? this.boolean_labels.true
            : this.boolean_labels.false;
        }
        break;
      case 'lastPitchDuration':
        let inReviewAt = moment(this.item.inReviewAt);
        var now = moment();
        let pitchDuration = null;
        let durationMode = 'months';

        let label = '';
        if (this.item.isInReview) {
          pitchDuration = now.diff(inReviewAt, 'months');
          if (pitchDuration == 0) {
            pitchDuration = now.diff(inReviewAt, 'days');
            durationMode = 'days';
          }
          label = this.translate.instant('CARDS.PITCH_IN_PROGRESS', {
            duration: pitchDuration,
          });
        } else {
          if (this.item.movedAt && this.item.inReviewAt) {
            let movedAt = moment(this.item.movedAt);
            pitchDuration = movedAt.diff(inReviewAt, 'months');
            if (pitchDuration == 0) {
              pitchDuration = movedAt.diff(inReviewAt, 'days');
              durationMode = 'days';
            }
            label = this.translate.instant('CARDS.PITCH_DURATION', {
              duration: pitchDuration,
            });
          } else {
            pitchDuration = null;
          }
        }

        if (pitchDuration == 1) {
          label =
            label +
            (durationMode == 'months'
              ? this.translate.instant('CARDS.MONTH')
              : this.translate.instant('CARDS.DAY'));
        } else if (pitchDuration == 0 || pitchDuration > 1) {
          label =
            label +
            (durationMode == 'months'
              ? this.translate.instant('CARDS.MONTHS')
              : this.translate.instant('CARDS.DAYS'));
        } else {
          label = '';
        }
        formattedValue = label;
        break;
      case 'budgets_total_n':
        formattedValue = value ? parseFloat(value).toFixed(1) : '0.0';
        let prev_budgets = JSON.parse(this.item.prev_budgets);
        let budgets_n = prev_budgets[this.currentYear];
        if (
          budgets_n &&
          budgets_n.isGenerated == false &&
          this.item.segment_name != 'Creative'
        ) {
          formattedValue = value ? parseFloat(value).toFixed(1) : '0.0';
        }
        // creative
        if (this.item.segment_name == 'Creative') {
          if (value && value != 0) {
            formattedValue = parseFloat(value).toFixed(1);
          } else {
            formattedValue = '';
          }
        }
        break;
      case 'budgets_total_n1':
      case 'budgets_total_n2':
        formattedValue = value ? parseFloat(value).toFixed(1) : '0.0';
        // creative
        if (this.item.segment_name == 'Creative') {
          if (value && value != 0) {
            formattedValue = parseFloat(value).toFixed(1);
          } else {
            formattedValue = '';
          }
        }
        break;
      default:
        break;
    }
    return formattedValue != '' && formattedValue != null
      ? formattedValue
      : null;
  }
}
