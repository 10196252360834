import { Component, Input } from '@angular/core';

@Component({
  selector: 'progressbar-chart',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
})
export class ProgressBarChartComponent {
  @Input() currentValue: number = 0; // Current progress value
  @Input() maxValue: number = 10; // Maximum value
  @Input() colors: string []=[]; // Maximum value
  @Input() barColor: string []=[]; // Maximum value
  ticks: number[] = [0, 10, 20, 30, 40, 50, 60, 70, 80,90,100]; // Tick marks


  getColor(){
    if(this.colors.length >= this.currentValue){
      return  this.colors[this.currentValue*10-1];
    }
    return '#337ab7';
  }
}
