import moment from 'moment';
import { Bespoke } from "./Bespoke";

export class CardMovement {
    id: number = null;
    card_id: number = null;
    agency_id: number = null;
    movedAt: string = null;
    unknownMovedAt: boolean = null;
    movedQuarter: string = null;
    effectiveAt: string = null;
    effectiveQuarter: string = null;
    inReviewAt: string = null;
    inReviewQuarter: string = null;
    isFollowingPitch: boolean = null;
    isIntegratedReview: boolean = null;
    bespokeUnit: string = null;
    type: string = null;
    declaredBudget: number = null;
    loyalty: number = null;
    zoneCoverage: string = null;
    zoneDetails: string = null;
    resignedAt: string = null;
    comment_consultants: string = null;
    comment_contenders: string = null;
    no_consultant: boolean = null;
    created_at: string = null;
    pitch_predictor:number = null;
    pitch_predictor_comment:string = null;

    agency: any[] = null;
    consultants: any[] = null;
    consultants_names: string = null;
    contenders: any[] = null;
    contenders_names: string = null;

    medias: any[] = null;
    assignments: any[] = null;
    cities: any[] = null;

    contractEndsAt:string = null;
    bespoke: Bespoke = null

    isConfidential: boolean = null;
    updated:boolean = false;

    
    constructor( id? : number|Object) {
        if (typeof(id)=='object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                switch(key) {
                    default:
                        this[key] = data[key];
                }
            }
        }
        this['unknownMovedAt'] = data['movedAt'] == '1970-01-01';
    }

    //calculate pitch predictor depending on the  last anouncement quarter and end of contract
    public getPitchPredictor(movedAt_n1 = null){
        let last_announcement_quarter = null;
        if(!movedAt_n1){
           last_announcement_quarter= moment().diff(moment(this.movedAt),'months');
        }else{
           last_announcement_quarter = moment().diff(moment(movedAt_n1),'months');
        }       

        // if(last_announcement_quarter >= 24 && last_announcement_quarter < 36 ){
        //     return 25;
        // }
        // else 
        if(last_announcement_quarter >= 36 && last_announcement_quarter < 48){
            return 50;
        }
        else if(last_announcement_quarter >= 48 && last_announcement_quarter < 84){
            return 75;
        } else{
            return 0;
        }
    }
};