import { Subzone } from './Subzone';
import { Currency } from './Currency';
import { CategoryCountry } from './CategoryCountry';

export class Country {
    id: number = null;
    subzone_id: number = null;
    zone_id: number = null;
    currency_id: number = null;
    currency_name: string = null;
    name: string = null;
    thresholdAmount: number = null;
    thresholdPredictorAmount: number = null;
    thresholdMcaAmount: number = null;
    adMoName: string = null;
    adMoNumber: number = null;
    adMoPeriod: string = null;
    isAgencyBillingDigital: boolean = null;
    isVisibleGAMA: boolean = null;
    isVisibleCARD: boolean = null;
    isVisibleNBB: boolean = null;
    isVisibleBMS: boolean = null;
    isVisibleTAF: boolean = null;
    isVisibleMCA: boolean = null;
    marketsStudied: Object = null;
    specificKeyPointOfMethodology: any = null;
    top_country: boolean = null;
    max_methodology_year: number = null;
    capital_id: number = null;
    card_count: number = null;
    updated_at: string = null;
    created_at: string = null;
    country_iso: string = null;
    marketsStudiedN: number = null;
    marketsStudiedN1: number = null;

    categories: any = null;
    subzone: Subzone = null;
    currency: Currency = null;
    comvergers: any[] = null;

    map_id: number = null;

    constructor( id? : number|Object) {
        if (typeof(id)=='object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                switch(key) {
                    case 'subzone':
                        this[key] = new Subzone(data[key]);
                        break;
                    case 'currency':
                        this[key] = new Currency(data[key]);
                        break;
                    // case 'categories':
                    //     this[key] = [];
                    //     for(let c in data[key]) {
                    //         this[key][c] = [];
                    //         data[key][c].forEach(item => {
                    //             this[key][c].push( new CategoryCountry(item) );
                    //         });
                    //     }
                    //     break;
                    default:
                        this[key] = data[key];
                }
            }
        }
    }

    getCategory(id){
        if(!this.categories || this.categories.length == 0 ){
            return null;
        }
        this.categories.find( (element) => { element.id === id; });
    }

};