import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import * as _ from 'lodash';
import { EditableGridField, FieldsColumns } from 'src/app/components/forms/editablegridfield/editable-grid-field.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'match-admo-dialog',
  templateUrl: './match-admo-dialog.component.html',
  styleUrls: ['./match-admo-dialog.component.scss']
})
export class MatchAdmoDialog implements OnInit {
  public ready = false;
  public btn_save_disabled: boolean = false;
  public btn_save_loader: boolean = false;
  public card = null;
  public year: number = null;
  public month: string = null;
  public country_id: number = null;
  public selectedTab: number = 0;
  public reloadAfterClose: boolean = false;
  public form: UntypedFormGroup = null;
  public selectedAdmos = [];
  public selectedAdmosIds = [];
  public admoExtraParameters = {
    status: JSON.stringify(['imported', 'disabled'])
  };
  public admosInputValue: string = null;
  public showAdmosList: boolean = true;
  public showAdmosBudgetsGrid: boolean = false;

  protected server_errors = null;
  public server_error = null;

  @ViewChild('admosBudgetsFields', { static: false }) admosBudgetsFields: EditableGridField;
  public budgetsData = [];
  public editable_grid_errors = {};
  public ValidationMessages = {
    REQUIRED_FIELD: this.translate.instant('ERRORS.FIELD_REQUIRED'),
  }
  public admosBudgetsFieldsColumnsDef: FieldsColumns[] = [
    {
      key: 'advertiser_name',
      fieldPlaceholder: "",
      width: '15%',
      editable: false
    },
    {
      key: 'total',
      fieldPlaceholder: this.translate.instant("ADMONITORINGS.MATCH_CARD.TOTAL"),
      width: '10%',
      inputType: 'number',
      formatter: (v) => {
        return (v !== null || v !== '') ? this.round(v) + '$M' : v;
      },
      editable: false
    },
    {
      key: 'admo_budget',
      fieldPlaceholder: this.translate.instant("ADMONITORINGS.MATCH_CARD.ADMO_BUDGET"),
      width: '10%',
      inputType: 'number',
      formatter: (v) => {
        return (v !== null || v !== '') ? this.round(v) + ' $M' : v;
      },
      validate: (v) => {
        let errors = [];
        if (!(v != null && v >= 0)) {
          errors.push({
            error: 'required',
            message: this.ValidationMessages.REQUIRED_FIELD
          });
        }
        return errors;
      },
      withInputChangeValue: true,
      onInputChangeValue: (v, row, columns, rowDef, rowIndex) => {
        // if(rowIndex == 0) {return;}

        let total_budget = 0;
        let digital_budget = null;

        let value;
        if (v != null && v >= 0) {
          value = v;
        } else {
          value = 0;
        }

        let traditional_budget = value;

        let pct_digital = null;
        if (row['budget_digital_pc'] != null && row['budget_digital_pc'] >= 0) {
          pct_digital = row['budget_digital_pc'];
        }
        if(pct_digital != null) {
          let pct_traditional_budget = 100 - pct_digital;
          if (pct_traditional_budget > 0) {
            total_budget = (traditional_budget * 100) / pct_traditional_budget;
          } else {
            total_budget = traditional_budget;
          }
          digital_budget = pct_digital / 100 * total_budget;
        }

        row['budget_digital'] = digital_budget;
        row['total'] = total_budget;
        return {
          row: row,
          reset_columns: true
        };
      },
    },
    {
      key: 'budget_digital',
      fieldPlaceholder: this.translate.instant("ADMONITORINGS.MATCH_CARD.DIG_BUDGET"),
      width: '10%',
      inputType: 'number',
      cssClass: (mainObject) => {
        let cls = "";
        if(mainObject.budget_method) {
          cls = "budget-method-"+mainObject.budget_method;
        }
        return cls;
      },
      formatter: (v) => {
        return (v !== null || v !== '') ? this.round(v) + ' $M' : v;
      },
      withInputChangeValue: true,
      onInputChangeValue: (v, row, columns, rowDef, rowIndex) => {
        // if(rowIndex == 0) {return;}
        let total_budget = 0;
        let pct_digital_budget = 0;

        let value;
        if (v != null && v >= 0) {
          value = v;
        } else {
          value = 0;
        }

        let traditional_budget = 0;
        if (row['admo_budget'] !== null && row['adno_budget'] !== "" && row['admo_budget'] >= 0) {
          traditional_budget = row['admo_budget'];
        }
        total_budget = value + traditional_budget;

        if (total_budget > 0) {
          pct_digital_budget = (value * 100) / total_budget;
        } else {
          pct_digital_budget = 0;
        }

        if (v === null || v === '') {
          pct_digital_budget = null;
        }

        row['budget_digital_pc'] = pct_digital_budget;
        if(pct_digital_budget !== null){
          row['budget_method'] = 'manual';
          if(this.card.budgets_method_n1 == 'no-dig' && pct_digital_budget == 0) {
            row['budget_method'] = 'no-dig';
          }
        } else {
          row['budget_method'] = 'default';
          if(this.card.budgets_method_n1 == 'no-dig') {
            row['budget_method'] = 'no-dig';
          }
        }
        row['total'] = total_budget;
        return {
          row: row,
          reset_columns: true
        };
      }
    },
    {
      key: 'budget_digital_pc',
      fieldPlaceholder: this.translate.instant("ADMONITORINGS.MATCH_CARD.DIG_PC_BUDGET"),
      width: '10%',
      inputType: 'number',
      cssClass: (mainObject) => {
        let cls = "";
        if(mainObject.budget_method) {
          cls = "budget-method-"+mainObject.budget_method;
        }
        return cls;
      },
      formatter: (v) => {
        return (v !== null || v !== '') ? this.round(v) + ' %' : v;
      },
      withInputChangeValue: true,
      onInputChangeValue: (v, row, columns, rowDef, rowIndex) => {
        // if(rowIndex == 0) {return;}
        let total_budget = 0;
        let digital_budget = 0;

        let value;
        if (v != null && v >= 0) {
          value = v;
        } else {
          value = 0;
        }

        let traditional_budget = 0;
        if (row['admo_budget'] != null && row['admo_budget'] >= 0) {
          traditional_budget = row['admo_budget'];
        }

        let pct_traditional_budget = 100 - value;
        if (pct_traditional_budget > 0) {
          total_budget = (traditional_budget * 100) / pct_traditional_budget;
        } else {
          total_budget = 0;
        }
        digital_budget = value / 100 * total_budget;

        if (v === null || v === '') {
          digital_budget = null;
        }

        row['budget_digital'] = digital_budget
        if(digital_budget !== null){
          row['budget_method'] = 'manual';
          if(this.card.budgets_method_n1 == 'no-dig' && digital_budget == 0) {
            row['budget_method'] = 'no-dig';
          }
        } else {
          row['budget_method'] = 'default';
          if(this.card.budgets_method_n1 == 'no-dig') {
            row['budget_method'] = 'no-dig';
          }
        }
        row['total'] = total_budget;

        return {
          row: row,
          reset_columns: true
        };
      }
    },
    {
      key: 'card_id',
      fieldPlaceholder: "",
      hidden: true
    },
    {
      key: 'budget_method',
      fieldPlaceholder: "",
      hidden: true
    },   
    // {
    //   key: 'agency_name',
    //   fieldPlaceholder: this.translate.instant("ADMONITORINGS.MATCH_CARD.AGENCY_NAME"),
    //   width: "10%",
    //   editable: false
    // },
    // {
    //   key: 'assignments_name',
    //   fieldPlaceholder: this.translate.instant("ADMONITORINGS.MATCH_CARD.ASSIGNMENTS_NAME"),
    //   width: "10%",
    //   editable: false
    // },
    // {
    //   key: 'medias_name',
    //   fieldPlaceholder: this.translate.instant("ADMONITORINGS.MATCH_CARD.MEDIAS_NAME"),
    //   width: "20%",
    //   editable: false
    // }
  ];

  public matchWithPublish: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MatchAdmoDialog>,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data, 
    private dataService: DataService,
    public dialog: MatDialog) { 
      this.form = new UntypedFormGroup({
        admosBudgets: new UntypedFormControl(null, [this.EditableGridValidation.bind(this)])
      }, {
        updateOn: 'change'
      });

      if(data) {
        if(data.item) {
          this.card = data.item;
        }
        if(data.year) {
          this.year = data.year;
        }
        if(data.month) {
          this.month = data.month;
        }
        if(data.country_id) {
          this.country_id = data.country_id
        }
        if(data.matchWithPublish ?? false) {
          this.matchWithPublish = data.matchWithPublish;
        }
      }
    }

  ngOnInit(): void {
    this.init();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    if(this.reloadAfterClose) {
      this.dialogRef.close('bt_save');
    } else {
      this.dialogRef.close('bt_no');
    }
  }

  round(v) {
    return Math.round((v + Number.EPSILON) * 10) / 10;
  }

  init(changeTab = true) {
    this.ready = false;
    let proms = [];

    if(this.card && this.card.id) {
      let card_id = this.card.id;
      let params = {
        admo_country_id: this.country_id
      }

      if(this.year) {
        params['admo_year'] = this.year;
        params['admo_month'] = this.month;
      }
      proms.push(
        this.dataService.getAsPromise(`admin/admonitorings/card/${card_id}`, {params: params}).then(res =>{
          if(res && res.data) {
            this.card = res.data;
            this.admosInputValue = this.card.advertiser_name.substring(0,5);
          }
        }).catch(err => {
          console.log(err);
        })
      )
    }

    Promise.all(proms).then(res => {
      this.ready = true;
    })
  }

  setData() {
    let data = {
      card_id: this.card.id,
      admos: null,
      country_id: this.country_id,
      year: this.year,
      month: this.month
    };

    let selected_admos = [];
    if(this.selectedAdmos) {
      let admosBudgets = this.form.value.admosBudgets;
      admosBudgets.forEach(element => {
        if(element.id) {
          selected_admos.push(element);
        }
      });
    }
    data['admos'] = selected_admos;

    return data;
  }

  onSave() {
    let data = this.setData();
    this.server_error = null;
    if(this.selectedAdmos.length) {
      this.save(data);
    } else {
      console.log("Form not valid!")
    }
  }

  save(data) {
    this.btn_save_disabled = true;
    this.btn_save_loader = true;
    // publish the admo, after the match is done
    if(this.matchWithPublish) {
      data['publish'] = true;
    }
    this.dataService.postAsPromise(`admin/admonitorings/assign-admos/${this.card.id}`, data).then(res => {
      this.btn_save_disabled = false;
      this.btn_save_loader = false;
      this.dialogRef.close({bt_save: true, data: res.data ?? null});
    }).catch(err => {
      this.btn_save_disabled = false;
      this.btn_save_loader = false;
    })
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  reValidateForms(form) {
    form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    for (let f in form.controls) {
      form.controls[f].markAsTouched();
      form.controls[f].updateValueAndValidity({ emitEvent: false });
    }
  }
  
  changeTab(event) {
    this.selectedTab = event;
  }

  onAdmoSelect(admo) {
    const o = _.find(this.selectedAdmos, {id: admo.id});
    if(!o) {
      this.selectedAdmos.push(admo);
      this.selectedAdmosIds.push(admo.id);
    }
  }

  removeAdmo(admo) {
    const o = _.find(this.selectedAdmos, {id: admo.id});
    if(o) {
      const index = _.findIndex(this.selectedAdmos, {id: admo.id});
      if (index !== -1) {
        this.selectedAdmos.splice(index, 1);
        this.selectedAdmosIds.splice(index, 1);
      }

      // remove admo from grid component
      const b = _.find(this.budgetsData, {id: admo.id});
      if(b) {
        const bindex = _.findIndex(this.budgetsData, {id: admo.id});
        if (bindex !== -1) {
          this.budgetsData.splice(bindex, 1);
        }
      }
      
      this.form.controls['admosBudgets'].setValue(this.budgetsData);
      if(this.admosBudgetsFields) {
        this.admosBudgetsFields.reloadField(false, true);
      }
    }
  }

  admosSearch(event){}

  next() {
    this.showAdmosList = false;
    this.showAdmosBudgetsGrid = true;
    this.initBudgetsEditableGrid(this.card);
  }

  back() {
    this.showAdmosList = true;
    this.showAdmosBudgetsGrid = false;
  }

  setEditableGridErrors(event) {
    if (event && event.control_name) {
      this.editable_grid_errors[event.control_name] = {
        valid: event.valid
      };
    }
  }

  EditableGridValidation(control: AbstractControl) {
    const parent = control["_parent"];
    if (parent) {
      let control_name = null;
      // find control name
      Object.keys(parent.controls).forEach(key => {
        let childControl = parent.get(key);
        if (childControl !== control) {
        } else {
          control_name = key;
        }
      });
      if (control_name && this.editable_grid_errors[control_name] && this.editable_grid_errors[control_name]['valid'] == false) {
        return { invalidEditableGridField: true };
      }
    }
    return null;
  }

  initBudgetsEditableGrid(card) {
    if(!this.budgetsData.length) {
      // this.budgetsData = [
      //   {
      //     id: null, 
      //     card_id: null, 
      //     budget_method: card.budgets_method_n1, 
      //     advertiser_name: card.advertiser_name, 
      //     total: this.round(card.budgets_total_n1), 
      //     admo_budget: card.budgets_traditional_n1, 
      //     budget_digital: card.budgets_digital_n1, 
      //     budget_digital_pc: card.budgets_pctdigital_n1,
      //     medias_name: card.medias_name,
      //     assignments_name: card.assignments_name,
      //     agency_name: card.agency_name
      //   }
      // ];
    }
  
    if(this.selectedAdmos) {
      this.selectedAdmos.forEach((admo) => {
        let b = _.find(this.budgetsData, {id: admo.id});
        if(!b) {
          let admo_data = {
            id: admo.id,
            card_id: card.id, 
            budget_method: admo.budget_digital == null ? (card.budgets_method_n1 ?? null) : (admo.budget_method ?? null), 
            advertiser_name: admo.admo_name_cleaned,
            total: null, 
            admo_budget: admo.admo_budget, 
            budget_digital: null, 
            budget_digital_pc: admo.budget_digital == null ?( card.budgets_pctdigital_n1 ?? null) : (admo.budget_digital_pc ?? null),
            medias_name: null,
            assignments_name: null,
            agency_name: null
          }
          
          //cald digital budget
          let total_budget = 0;
          let digital_budget = 0;

          let budget_digital_pc;
          if (admo_data.budget_digital_pc != null && admo_data.budget_digital_pc >= 0) {
            budget_digital_pc = admo_data.budget_digital_pc;
          } else {
            budget_digital_pc = 0;
          }

          let traditional_budget = 0;
          if (admo_data.admo_budget != null && admo_data.admo_budget >= 0) {
            traditional_budget = admo_data.admo_budget;
          }

          let pct_traditional_budget = 100 - budget_digital_pc;
          if (pct_traditional_budget > 0) {
            total_budget = (traditional_budget * 100) / pct_traditional_budget;
          } else {
            total_budget = 0;
          }
          digital_budget = budget_digital_pc / 100 * total_budget;

          if (admo_data.budget_digital_pc === null || admo_data.admo_budget == null) {
            digital_budget = null;
          }

          admo_data['budget_digital'] = digital_budget;
          admo_data['total'] = total_budget;
        
          this.budgetsData.push(admo_data);
        }
      });
    }

    this.form.controls['admosBudgets'].setValue(this.budgetsData);
    if(this.admosBudgetsFields) {
      this.admosBudgetsFields.reloadField(false, true);
    }
  }
}
