import { HasRolesDirective } from './../../directives/hasroles.directive';
import { HasNotRolesDirective } from './../../directives/hasnotroles.directive';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { EventsDialog } from './../../components/dialogs/eventsdialog/events-dialog.component';
import { FileUploadInput } from './../../components/forms/fileupload-field/fileupload-field.component';
import { AlertComponent } from './../../components/misc/alert/alert.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SecurePipe } from './../../pipes/secure.pipe';
import { EditUserDialog } from '../../components/dialogs/userdialog/user-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { ValidatorsModule } from 'ngx-validators';
import { ConfirmDialog } from 'src/app/components/dialogs/confirmdialog/confirm-dialog.component';
import { HasPermsDirective } from 'src/app/directives/hasperms.directive';
import { DropDownMenuComponent } from '../../components/menus/dropdownmenu/dropdownmenu.component';
import { HasNotPermsDirective } from './../../directives/hasnotperms.directive';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { QuillModule } from 'ngx-quill';
import { TableComponent } from './../../components/tables/table/table.component';

import { AutocompleteField } from 'src/app/components/forms/autocomplete-field/autocomplete-field.component';
import { EditableGridField } from 'src/app/components/forms/editablegridfield/editable-grid-field.component';
import { AjaxAutocompleteChipsInput } from 'src/app/components/forms/ajax-autocomplete-chips/ajax-autocomplete-chips.component';
import { AutocompleteCountryFieldComponent } from 'src/app/components/forms/autocomplete-country-field/autocomplete-country-field.component';
import { MenuItemComponent } from 'src/app/components/menus/dropdownmenu/menu-item/menu-item.component';
import { PublishDialog } from 'src/app/components/dialogs/publishdialog/publish-dialog.component';
import { ExportDialog } from 'src/app/components/dialogs/exportdialog/export-dialog.component';
import { CityDialog } from 'src/app/components/dialogs/citydialog/city-dialog.component';
import { InfoDialog } from 'src/app/components/dialogs/infodialog/info-dialog.component';
import { CardInfoDialog } from 'src/app/components/dialogs/cardinfodialog/card-info-dialog.component';
import { MatchAdmoDialog } from '../admin/admonitorings/components/match-admo-dialog/match-admo-dialog.component';
import { SearchAdmo } from '../admin/admonitorings/components/search-admo/search-admo.component';

import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarComponent } from 'src/app/components/misc/avatar/avatar.component';
import { GridComponent } from 'src/app/components/misc/grid/grid.component';
import { GridEditorDialog } from 'src/app/components/misc/grid/grideditordialog/grid-editor-dialog.component';
import { GridPreviewDialog } from 'src/app/components/misc/grid/gridpreviewdialog/grid-preview-dialog.component';
import { Nl2BrPipe } from 'src/app/pipes/nl2br.pipe';
import { DeleteCardDialog } from 'src/app/components/dialogs/deletecarddialog/deletecard-dialog.component';
import { HistoryDialog } from 'src/app/components/dialogs/historydialog/history-dialog.component';
import { BookmarkComponent } from 'src/app/components/misc/bookmark/bookmark.component';
import { BookmarkDialog } from 'src/app/components/dialogs/bookmarkdialog/bookmark-dialog.component';
import { CleanRulesDialog } from '../admin/admonitorings/components/clean-rules-dialog/clean-rules-dialog.component';
import { GridPreview } from 'src/app/components/misc/grid/gridpreview/grid-preview.component';
import { WorkflowComponent } from 'src/app/components/misc/workflow/workflow.component';
import { MatTreeModule } from '@angular/material/tree';
import { MovementDialog } from '../admin/cards/components/dialogs/movement-dialog/movement-dialog.component';
import { AutoclosePanelDirective } from 'src/app/directives/autoclosepanel.directive';
import { LogoComponent } from 'src/app/components/misc/logo/logo.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SafeHtmlDirective } from 'src/app/directives/safehtml.directive';
import { ProgressBarChartComponent } from 'src/app/components/charts/progressbar/progressbar.component';

@NgModule({
  declarations: [
    DropDownMenuComponent,
    ConfirmDialog,
    EventsDialog,
    ExportDialog,
    EditUserDialog,
    PublishDialog,
    MovementDialog,
    HasRolesDirective,
    HasNotRolesDirective,
    HasPermsDirective,
    HasNotPermsDirective,
    AvatarComponent,
    SecurePipe,
    SafePipe,
    AlertComponent,
    TableComponent,
    AutocompleteField,
    EditableGridField,
    AjaxAutocompleteChipsInput,
    FileUploadInput,
    AutocompleteCountryFieldComponent,
    MenuItemComponent,
    CityDialog,
    InfoDialog,
    CardInfoDialog,
    MatchAdmoDialog,
    SearchAdmo,
    GridComponent,
    GridEditorDialog,
    GridPreviewDialog,
    GridPreview,
    Nl2BrPipe,
    DeleteCardDialog,
    HistoryDialog,
    BookmarkDialog,
    BookmarkComponent,
    CleanRulesDialog,
    WorkflowComponent,
    AutoclosePanelDirective,
    LogoComponent,
    SafeHtmlDirective,
    ProgressBarChartComponent
  ],
  imports: [
    LayoutModule,
    CommonModule,
    ValidatorsModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatMenuModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatBadgeModule,
    FormsModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    TranslateModule,
    MatDialogModule,
    MatAutocompleteModule,
    DragDropModule,
    MomentModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatCheckboxModule,
    QuillModule,
    MatTooltipModule,
    MatSelectCountryModule.forRoot('en'),
    MatTreeModule,
    MatStepperModule,
    MatButtonToggleModule,
  ],
  exports: [
    CommonModule,
    LayoutModule,
    ValidatorsModule,
    MatButtonModule,
    MatBadgeModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatMenuModule,
    MatSelectModule,
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    FormsModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    DropDownMenuComponent,
    ConfirmDialog,
    EventsDialog,
    ExportDialog,
    EditUserDialog,
    PublishDialog,
    MatDialogModule,
    TranslateModule,
    HasPermsDirective,
    HasNotPermsDirective,
    HasRolesDirective,
    HasNotRolesDirective,
    MatAutocompleteModule,
    DragDropModule,
    MomentModule,
    AvatarComponent,
    SecurePipe,
    SafePipe,
    Nl2BrPipe,
    FlexLayoutModule,
    MatSnackBarModule,
    AlertComponent,
    TableComponent,
    AutocompleteField,
    EditableGridField,
    AjaxAutocompleteChipsInput,
    FileUploadInput,
    MatSelectCountryModule,
    AutocompleteCountryFieldComponent,
    MenuItemComponent,
    CityDialog,
    InfoDialog,
    CardInfoDialog,
    MatchAdmoDialog,
    SearchAdmo,
    GridComponent,
    GridEditorDialog,
    GridPreviewDialog,
    GridPreview,
    DeleteCardDialog,
    HistoryDialog,
    MatTooltipModule,
    BookmarkDialog,
    BookmarkComponent,
    CleanRulesDialog,
    WorkflowComponent,
    MatTreeModule,
    MovementDialog,
    AutoclosePanelDirective,
    LogoComponent,
    MatStepperModule,
    MatButtonToggleModule,
    SafeHtmlDirective,
    ProgressBarChartComponent
  ],
})
export class SharedModule {}
