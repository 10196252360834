import { EditUserDialog } from '../components/dialogs/userdialog/user-dialog.component';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { DataService } from './data.service';
import { MatDialog } from '@angular/material/dialog';
import { BookmarkService } from './bookmark.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public pageTitle = '';
  public pageTitleLink = null;

  public canAddBookmark: boolean = false;
  public showBookmarks: boolean = false;
  public activeRouteSnapshot: ActivatedRouteSnapshot = null;

  protected main_menu_auth = null;

  protected main_menu = [
    //  { type: "item", label: "NAV.LOGOUT", path: "/logout", icon: "exit_to_app" },
  ];

  protected profile_menu_auth = [
    {
      type: 'item',
      label: 'NAV.PROFILE',
      icon: 'account_circle',
      click: () => {
        this.showUserDialog();
      },
    },
    // { type: "item", label: "NAV.LINKEDIN_LOGIN", icon: "account_circle", click: () => { this.linkedinLogin() } },
    // { type: "item", label: "NAV.APP_LINKEDIN_LOGIN", icon: "account_circle", click: () => { this.linkedinAppLogin() } },
    //{ type: "separator" },
  ];
  protected profile_menu = [
    //  { type: "item", label: "NAV.LOGOUT", path: "/logout", icon: "exit_to_app" },
  ];

  constructor(
    public auth: AuthenticationService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private dataService: DataService,
    public bookmarkService: BookmarkService
  ) {
    const self = this;
    this.showBookmarks = this.bookmarkService.showBookmarks;
    this.initMenu();
  }

  getMenu(menu) {
    let user = this.auth.getCurrentAuthUser();

    switch (menu) {
      case 'main':
        return user && user.exists() ? this.main_menu_auth : this.main_menu;
        break;
      case 'profile':
        return user && user.exists()
          ? this.profile_menu_auth
          : this.profile_menu;
        break;
    }
  }

  logout() {
    this.auth.logout();
  }

  initMenu() {
    this.main_menu_auth = [
      {
        type: 'item',
        label: this.translate.instant('NAV.HOME'),
        path: '/dashboard',
        icon: 'home',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      {
        type: 'group',
        label: this.translate.instant('NAV.DATAS'),
        icon: 'folder',
        roles: ['super-admin', 'comverger'],
        items: [
          {
            type: 'item',
            label: this.translate.instant('NAV.CURRENCIES'),
            path: '/currencies',
            icon: 'monetization_on',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.ZONES'),
            path: '/zones',
            icon: 'language',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.SUBZONES'),
            path: '/subzones',
            icon: 'map',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.COUNTRIES'),
            path: '/countries',
            icon: 'public',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.CITIES'),
            path: '/cities',
            icon: 'location_city',
            roles: ['super-admin', 'comverger'],
          },
          { type: 'separator' },
          {
            type: 'item',
            label: this.translate.instant('NAV.PARENTS_CO_GAMA'),
            path: '/parentcogamas',
            icon: 'qr_code',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.PARENTS_CO'),
            path: '/parentcos',
            icon: 'qr_code',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.ADVERTISERS'),
            path: '/advertisers',
            icon: 'school',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.BRANDS'),
            path: '/brands',
            icon: 'qr_code',
            roles: ['super-admin', 'comverger'],
          },
          { type: 'separator' },
          {
            type: 'item',
            label: this.translate.instant('NAV.CATEGORIES_GAMA'),
            path: '/categorygamas',
            icon: 'category',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.CATEGORIES'),
            path: '/categories',
            icon: 'category',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.SEGMENTS'),
            path: '/segments',
            icon: 'speed',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.MEDIA'),
            path: '/medias',
            icon: 'qr_code',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.ASSIGNMENTS'),
            path: '/assignments',
            icon: 'loop',
            roles: ['super-admin', 'comverger'],
          },
          { type: 'separator' },
          {
            type: 'item',
            label: this.translate.instant('NAV.HOLDINGS'),
            path: '/holdings',
            icon: 'note',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.GROUPS'),
            path: '/groups',
            icon: 'groups',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.NETWORKS'),
            path: '/agencynetworks',
            icon: 'language',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.AGENCIES.AGENCIES'),
            path: '/agencies',
            icon: 'school',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.BESPOKES'),
            path: '/bespokes',
            icon: 'list',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.CONSULTANTS'),
            path: '/consultants',
            icon: 'support_agent',
            roles: ['super-admin', 'comverger'],
          },
          { type: 'separator' },
          {
            type: 'item',
            label: this.translate.instant('NAV.JOBTITLES'),
            path: '/jobtitles',
            icon: 'work',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.AGENCIESUNIVERS'),
            path: '/agencyunivers',
            icon: 'public',
            roles: ['super-admin', 'comverger'],
          },
        ],
      },
      { type: 'separator' },
      {
        type: 'item',
        label: this.translate.instant('NAV.CARDS.CARDS'),
        path: '/cards',
        icon: 'list',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      {
        type: 'item',
        label: this.translate.instant('NAV.ADMONITORINGS'),
        path: '/admonitorings',
        icon: 'list',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      {
        type: 'item',
        label: this.translate.instant('NAV.BMS'),
        path: '/bms',
        icon: 'list',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      {
        type: 'item',
        label: this.translate.instant('NAV.NBB'),
        path: '/nbb',
        icon: 'list_alt',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      // { type: "item", label: this.translate.instant("NAV.HEADCOUNT"), path: "/headcount", icon: "list", roles: ['super-admin', 'comverger'] },
      // { type: "separator" },
      {
        type: 'item',
        label: this.translate.instant('NAV.MCA'),
        path: '/bpi',
        icon: 'list',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      {
        type: 'group',
        label: this.translate.instant('NAV.HEADCOUNT'),
        icon: 'folder',
        roles: ['super-admin', 'comverger'],
        items: [
          {
            type: 'item',
            label: this.translate.instant('NAV.DATA'),
            path: '/headcount',
            icon: 'list',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.WORKFLOW'),
            path: '/workflows',
            icon: 'cached',
            roles: ['super-admin', 'comverger'],
          },
        ],
      },
      { type: 'separator' },
      {
        type: 'item',
        label: this.translate.instant('NAV.TALENTS.TALENTS'),
        path: '/talents',
        icon: 'list',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      {
        type: 'item',
        label: this.translate.instant('NAV.MASU'),
        path: '/masu',
        icon: 'attach_money',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      {
        type: 'item',
        label: this.translate.instant('NAV.SPOTLIGHT'),
        path: '/spotlights',
        icon: 'highlight',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      {
        type: 'item',
        label: this.translate.instant('NAV.AGENCIES.AGENCY_PROFILES'),
        path: '/agencies/profiles',
        icon: 'account_box',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },
      {
        type: 'item',
        label: this.translate.instant('NAV.RADARS.RADAR'),
        path: '/radars',
        icon: 'radar',
        roles: ['super-admin', 'comverger'],
      },
      { type: 'separator' },

      {
        type: 'group',
        label: this.translate.instant('NAV.INFO'),
        icon: 'info',
        roles: ['super-admin', 'comverger'],
        items: [
          {
            type: 'item',
            label: this.translate.instant('NAV.NEWS'),
            path: '/news',
            icon: 'campaign',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.SCHEDULES.SCHEDULES'),
            path: '/schedules',
            icon: 'event',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.SEARCHES.SEARCHES'),
            path: '/searches',
            icon: 'search',
            roles: ['super-admin', 'comverger'],
          },
        ],
      },
      { type: 'separator', roles: ['super-admin', 'comverger'] },
      {
        type: 'group',
        label: this.translate.instant('NAV.CUSTOMERS'),
        icon: 'business_center',
        roles: ['super-admin', 'comverger'],
        items: [
          {
            type: 'item',
            label: this.translate.instant('NAV.CLIENTS'),
            path: '/clients',
            icon: 'work_outline',
            roles: ['super-admin'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.PRICING_OFFERS'),
            path: '/pricing/offers',
            icon: 'payments',
            roles: ['super-admin'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.USERS'),
            path: '/customers',
            icon: 'people',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.CONTACTS'),
            path: '/contacts',
            icon: 'contact_mail',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.CAMPAIGNS'),
            path: '/campaigns',
            icon: 'speaker_notes',
            roles: ['super-admin', 'comverger'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.MS_ADMONITORINGS'),
            path: '/ms-admonitorings',
            icon: 'list',
            roles: ['super-admin', 'comverger'],
          },
        ],
      },
      { type: 'separator', roles: ['super-admin'] },
      {
        type: 'group',
        label: this.translate.instant('NAV.SETTINGS'),
        icon: 'settings',
        roles: ['super-admin'],
        items: [
          // { type: "item", label: "NAV.USERS", path: "/users", icon: "people", roles: ['super-admin'] },
          {
            type: 'item',
            label: this.translate.instant('NAV.PARAMETERS'),
            path: '/parameters',
            icon: 'settings',
            roles: ['super-admin'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.TASKS_PAGE'),
            path: '/admin/tasks',
            icon: 'build',
            roles: ['super-admin'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.QUERIES'),
            path: '/queries',
            icon: 'receipt',
            roles: ['super-admin'],
          },
          {
            type: 'item',
            label: this.translate.instant('NAV.PRICING_SETTINGS'),
            path: '/pricing/settings',
            icon: 'payments',
            roles: ['super-admin'],
          },

          {
            type: 'item',
            label: this.translate.instant('NAV.SENT_EMAILS'),
            path: '/sent-emails',
            icon: 'mail',
            roles: ['super-admin', 'comverger'],
          },

          // { type: "item", label: "NAV.HELPMESSAGES", path: "/help", icon: "help_outline", roles:['super-admin'] },
        ],
      },
    ];

    this.profile_menu_auth = [
      {
        type: 'item',
        label: this.translate.instant('NAV.PROFILE'),
        icon: 'account_circle',
        click: () => {
          this.showUserDialog();
        },
      },
    ];
  }

  setTitle(title, subtitle = '', link = null) {
    if (title) {
      this.pageTitle = this.translate.instant(title);
      if (subtitle) {
        this.pageTitle += ' : ' + subtitle;
      }
    } else {
      this.pageTitle = '';
    }
    this.pageTitleLink = link;
  }

  getPageTitle() {
    return this.pageTitle;
  }

  showUserDialog() {
    let title = this.translate.instant('USER.MY_PROFILE');
    let user = this.auth.getCurrentUser();
    console.log('user = ', user);
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '370px',
      autoFocus: false,
      disableClose: true,
      data: {
        title: title,
        item: user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.auth.loadCurrentUser();
    });
  }

  linkedinLogin() {
    this.dataService.getAsPromise('linkedin/login').then((res) => {
      console.log('LinkedIn login endpoint', res);
      location.href = res.url;
    });
  }

  linkedinAppLogin() {
    this.dataService.getAsPromise('linkedin/applogin').then((res) => {
      console.log('LinkedIn app login', res);
    });
  }

  setBookMsrksPermision(canAddBookmark) {
    this.canAddBookmark = canAddBookmark;
    this.bookmarkService.canAddBookmark = canAddBookmark;
    this.bookmarkService.setLastQueryParams(null);
  }

  setActivatedRouteSnapshot(snp) {
    this.activeRouteSnapshot = snp;
  }
}
