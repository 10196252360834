import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[safeHtml]'
})
export class SafeHtmlDirective {
  @Input() set safeHtml(html: string) {
    this.elementRef.nativeElement.innerHTML = html;
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
}
